angular.module('MyHippoProducer.Components').directive('formTiles',  function () {
    return {
        restrict: 'A',
        scope: {
            section: '<',
            colspan: '<',
        },
        templateUrl: 'components/form/tiles/tiles.html',
        controller: function ($scope, PolicyService, WorkflowAdapter, $mdDialog) {
            $scope.PolicyService = PolicyService;
            const { properties, fields } = $scope.section;

            $scope.addItem = () => {
                return $mdDialog.show({
                    locals: { fields, section: $scope.section },
                    parent: angular.element(document.body),
                    controller: function ($scope, fields, section) {
                        $scope.createRange = _.range;
                        $scope.forms = { formData: {} };
                        $scope.fields = fields;
                        $scope.title = properties.title;
                        $scope.states = PolicyService.getAllStates();
                        const fieldHasInfo = (fields.find(field => field.info));
                        $scope.tooltip = fieldHasInfo && fieldHasInfo.info;
                        $scope.policyInfo = PolicyService.policyInfo;

                        $scope.data = { id: `new${new Date().getTime().toString()}` };

                        fields.forEach(field => {
                            if (field.default !== undefined) {
                                $scope.data[field.key] = field.default;
                            }
                        });

                        $scope.save = function () {
                            $scope.forms.formData.$submitted = true;
                            if ($scope.forms.formData.$valid) {
                                section.addItem($scope.data);
                                $mdDialog.hide($scope.data);
                            }
                        };
                        $scope.cancel = function () {
                            $mdDialog.cancel();
                        };
                    },
                    templateUrl: `/modals/edit-forms/${properties.key}.html`,
                });
            };

            if ($scope.section.items.length === 0) {
                $scope.addItem().then(
                    function (item) {
                        logger.info(`add new item ${JSON.stringify(item)} successfully`);
                    },
                    function () {
                        $scope.$parent.field.display = false;
                    }
                );
            }
        }
    };
});
